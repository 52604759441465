import { useRef, useEffect } from "react";

interface VideoProps {
    widthClass: string;
    src: string;
}

export default function VideoContainer(props: VideoProps) {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.loop = true;
            videoElement.muted = true; // Mute the video if needed
            videoElement.play().catch((error) => {
                console.error("Error playing video:", error);
            });
        }
    }, [props.src]);

    return (
        <>
            <video
                ref={videoRef}
                width="1920"
                height="1080"
                className={`${props.widthClass} aspect-square object-cover`}
                controls={false} // Hide native controls
                src={props.src}
                style={{ cursor: 'pointer' }} // Change cursor to pointer when hovering over video
                muted
                loop
                playsInline
            />
        </>
    );
}
