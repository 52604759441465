'use client';

import { Separator } from '@/components/ui/separator';
import { useSession } from 'next-auth/react';
import Image from 'next/image';
import Link from "next/link";

const Footer = () => {

    const { data: session, status, update } = useSession();
    
    return (
        <div className='mt-8 w-full sticky top-full'>    
            <Separator className="my-0 bg-ptgrey/50" />
            <div className="p-2">
                <div className='flex space-x-2 flex-row text-sm my-4 mx-2'>
                    <p className='font-bold'>Featured ProxyTwins: </p>
                    <Link href={'/u/mark'} className={'underline'}>Mark</Link> 
                    <Separator orientation="vertical" className='bg-ptgrey' />
                    <Link href={'/u/dan'} className={'underline'}>Dan</Link> 
                    <Separator orientation="vertical" className='bg-ptgrey'  />
                    <Link href={'/u/lucie'} className={'underline'}>Lucie</Link>
                </div>
                <div className='flex flex-row flex-wrap text-sm mb-4'>
                    <Link className="basis-full m-2" href={'/'}>Home</Link>
                    <Link className="basis-full m-2" href={'/plans'}>Plans</Link>
                    {!session && <Link className="basis-full m-2" href={'/signup'}>Sign up</Link>}
                    <Link className="basis-full m-2" href={'/u/feedback'}>Feedback</Link>
                    <Link className="basis-full m-2" href={'/u/customer-care'}>Customer Care</Link>
                    <Link className="basis-full m-2" href={'https://www.proxytwin.com'}>About ProxyTwin</Link>
                </div>
                <div className=""><Link href={'https://www.proxytwin.com'}><Image width={150} height={40} src="/images/ProxyTwin-Logo-Landscape.svg" alt="ProxyTwin" title="ProxyTwin" /></Link></div>
                <div className="p-2 flex justify-between text-xs">
                    <div>&copy; {new Date().getFullYear()} ProxyTwin Pty Ltd </div>
                    <div><Link href="/privacy">Privacy</Link> | <Link href="/terms">Terms</Link></div>
                </div>
            </div>  
        </div>
    )
}
export default Footer;