"use client"
import { signOut } from "next-auth/react";
import { Button } from "@/components/ui/button"
import Link from "next/link";
import { LogOut } from "lucide-react";

/**
 * 
 * Important - use client side when included in ShadCN DropDownMenu to avoid issuw with React form
 */
export function SignOut() {

  const clickHandler = () =>{    
    signOut({ callbackUrl: '/', redirect:true });
  }

  return (
    <div className="cursor-pointer w-full" onClick={clickHandler} >
      <LogOut className="mr-2 h-4 w-4 inline" /><Button variant={"ghost"} className={"m-0 p-0 h-6"} >Log out</Button>  
    </div>
      
      
  )
}