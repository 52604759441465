"use client"; // This is the client component

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { CircleUserRound, MessageCircleMore, Crown, LockKeyhole, UserRoundPen, Headset, MessageCircle } from "lucide-react";
import { SignOut } from "@/components/sign-out";
import Link from "next/link";

type ProfileMenuProps = {
  profileSlug: string | null;
  authFirstName: string;
  authLastName: string;
  isAdmin: boolean;
};

export default function ProfileMenu({ profileSlug, authFirstName, authLastName, isAdmin }: ProfileMenuProps) {
  
  const handleProfileClick = () => {
    if (profileSlug) {
      window.location.href = `/u/${profileSlug}`;
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Avatar>
          <AvatarImage src="" />
          <AvatarFallback>{authFirstName.substring(0, 1)}{authLastName.substring(0, 1)}</AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          {profileSlug && (
            <>
              <DropdownMenuItem onClick={handleProfileClick}>
                <CircleUserRound className="mr-2 size-4 inline-block" />
                <span>Profile</span>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Link href={`/u/${profileSlug}/messages`} className="flex items-center w-full">
                  <MessageCircleMore className="mr-2 size-4 inline-block" />
                  <span>Messages</span>
                </Link>
              </DropdownMenuItem>
            </>
          )}
          <DropdownMenuItem>
            <Link href={"/training"} className="flex items-center w-full">
              <UserRoundPen className="mr-2 size-4 inline-block" />
              <span>Training</span>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Link href={"/plans"} className="flex items-center w-full">
              <Crown className="mr-2 size-4 inline-block" />
              <span>Plans</span>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem>
            <Link href={"/u/feedback"} className="flex items-center w-full">
              <MessageCircle className="mr-2 size-4 inline-block" />
              <span>Feedback</span>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <Link href={"/u/customer-care"} className="flex items-center w-full">
              <Headset className="mr-2 size-4 inline-block" />
              <span>Customer Care</span>
            </Link>
          </DropdownMenuItem>
          {isAdmin && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuItem>
                <Link href={"/admin"} className="flex items-center w-full">
                  <LockKeyhole className="mr-2 size-4 inline-block" />
                  <span>Admin</span>
                </Link>
              </DropdownMenuItem>
            </>
          )}
          <DropdownMenuSeparator />
          <DropdownMenuItem>
            <SignOut />
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
