'use client'

import VideoContainer from "./VideoContainer"

export function VideoWrapper(props: { src: string}){
    
    return (
        <div className="flex justify-center items-center overflow-hidden">
            <VideoContainer src={props.src} widthClass='max-w-md' />
        </div>       
    )
        
}